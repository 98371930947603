export const dependencyInYaml = `
- id: A1
  notes: front ticket
  points: 2

- id: A2
  notes: backend route
  position: -195,-5
  parent: A1
  owner: User
  points: 23
`
